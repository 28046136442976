<template>
  <div
    class="vue-dropdown"
    :class="[{ open: open }, { 'disabled': disabled }, { 'small': small }, { 'bg-grey': bgGrey }, { 'mobile': mobile }]"
    :style="{ 'width': width }"
  >
    <div
      v-if="showPlaceholder"
      class="vue-dropdown-placeholder"
      @click="setOpen"
    >
      {{ fieldSubTitle ? valueSubTitle : label }}
    </div>
    <button
      ref="buttonLabel"
      class="vue-dropdown-label"
      :class="[{'unchecked': !checked}, {'error': error}, {'with-placeholder': showPlaceholder }, {'big': full || fieldSubTitle}]"
      :disabled="disabled"
      @click="setOpen"
      @blur="onBlur"
      @keydown.enter.prevent.stop="onEnter"
      @keydown.down.prevent="onDown"
      @keydown.up.prevent="onUp"
    >
      <span
        v-if="placeholderIconName"
        class="vue-dropdown-label-ph-icon"
        :class="[placeholderIconName]"
        v-html="placeholderIcons[placeholderIconName]"
      />
      <span
        v-if="withIcon"
        class="vue-dropdown-label-icon"
        :class="{ 'colored' : coloredIcon }"
        v-html="getIconSelected"
      />
      <span
        v-if="!location && !multiselection"
        class="vue-dropdown-label-first"
        :class="{ 'with-icon' : showRightIconLabel }"
      >
        {{ valueTitle }}
        <span
          v-if="showRightIconLabel"
          class="fieldtitle-icon"
        >
          <VTooltip>
            <span
              class="fixed-colors"
              v-html="rightIcon"
            />
            <template #popper>
              <span class="gpt-model-chat-options-item-tooltip-text small">{{ rightIconTooltip }}</span>
            </template>
          </VTooltip>
        </span>
      </span>
      <div v-else-if="!location && multiselection">
        <span
          v-if="options.filter(o => o.selected).length === 0"
          class="vue-dropdown-multiple-empty-text"
        >
          {{ multipleEmptyText }}
        </span>
        <span
          v-else
          class="vue-dropdown-multiple-items"
        >
          {{ multipleItems }}
        </span>
      </div>
      <div
        v-else
        class="vue-dropdown-label-first"
      >
        <span
          class="vue-dropdown-label-first-flag"
          v-html="locationFlag"
        />
        <span
          v-if="selectedLocation"
          class="location-country"
        >
          {{ valueTitle + '&nbsp;—&nbsp;' + selectedLocation.Country }}
        </span>
      </div>
    </button>
    <div
      v-if="open"
      class="vue-dropdown-list"
      :class="{ 'with-focus': !withFocus }"
    >
      <PerfectScrollbar
        ref="listOptions"
        class="vue-dropdown-list-ps"
        :options="{wheelPropagation: false}"
      >
        <div
          v-for="(item, index) in optionsCurrent"
          :key="item[index]"
          ref="optionsItems"
          class="vue-dropdown-list-ps-item"
          :class="{
            'vue-dropdown-list-ps-item--selected': valueTitle === item.Title || valueTitle === item.name,
            'with-location' : location,
            'location-disabled' : item.ComingSoon
          }"
          :value="item[fieldValue]"
          @mousedown="changeHandler(item, index)"
        >
          <span
            v-if="withIcon"
            class="vue-dropdown-label-icon"
            :class="{ 'colored' : coloredIcon }"
            v-html="getIcon(item)"
          />
          <span
            v-else-if="location"
            class="vue-dropdown-list-ps-item-flag"
            v-html="item.Flag"
          />
          <div v-if="fieldSubTitle">
            {{ item[fieldSubTitle] }}
          </div>
          <BaseCheckBox
            v-if="multiselection"
            v-model="item[fieldSelector]"
            class="vue-dropdown-list-checkbox"
            @update:model-value="item[fieldSelector] = $event"
          >
            {{ item[fieldTitle] }}
          </BaseCheckBox>
          <div
            v-else
            class="fieldtitle"
            :class="{ 'with-icon' : item[fieldRightIcon] }"
          >
            <span class="fieldtitle-label XXX">
              {{ item[fieldTitle] }}
            </span>
            <span
              v-if="item[fieldRightIcon]"
              class="fieldtitle-icon"
            >
              <span
                class="fixed-colors"
                v-html="rightIcon"
              />
            </span>
            <span
              v-if="location"
              class="location-country"
            >
              {{ '&nbsp;—&nbsp;' + item.Country }}
              <span
                v-if="item.ComingSoon"
                class="location-country-coming-soon"
              > ({{ $t('shared.location-select.tempTitle') }})</span>
            </span>
            <span class="selected-icon" />
          </div>
        </div>
      </PerfectScrollbar>
    </div>
    <div
      v-show="error"
      class="vue-dropdown-error"
    >
      <span
        v-for="(item, idx) in serverError"
        :key="idx"
      >
        {{ item }}
      </span>
    </div>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/base/BaseCheckBox.vue'
import svgOS from '@/svg/os.js'
import appIcons from '@/svg/configured_apps.js'
import { ICON_FILTER, ICON_SORT, ICON_AMAZON_AWS, ICON_GOOGLE_CLOUD, ICON_WEB } from '@/svg/icons.js'

export default {
  name: 'BaseDropdown',
  components: {
    BaseCheckBox,
  },
  props: {
    options: { type: Array, default: () => [] },
    optionsFiltered: { type: Array, default: () => [] },
    label: { type: String, default: '' },
    fieldValue: { type: String, default: 'id' },
    fieldTitle: { type: String, default: 'title' },
    fieldInlineIcon: { type: String, default: '' },
    fieldSubTitle: { type: String, default: null },
    fieldSelector: { type: String, default: null },
    modelValue: { type: [String, Number, Boolean], default: null },
    full: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    width: { type: String, default: '' },
    serverError: { type: Array, default: () => [] },
    withIcon: { type: Boolean, default: false },
    withFocus: { type: Boolean, default: false },
    location: { type: Boolean, default: false },
    multiselection: { type: Boolean, default: false },
    multipleEmptyText: { type: String, default: '' },
    placeholderIconName: { type: String, default: '' },
    bgGrey: { type: Boolean, default: false },
    mobile: { type: Boolean, default: false },
    fieldRightIcon: { type: String, default: '' },
    rightIcon: { type: String, default: '' },
    rightIconTooltip: { type: String, default: '' },
    filterOptions: { type: Boolean, default: false },
    coloredIcon: { type: Boolean, default: false },
  },
  emits: ['select', 'update:modelValue', 'enter'],
  data () {
    return {
      open: false,
      selectedOptionIndex: -1,
      serverErrorData: '',
      osMap: {
        Windows: svgOS.Windows,
        Ubuntu: svgOS.Ubuntu,
        Debian: svgOS.Debian,
        FreeBSD: svgOS.FreeBSD,
        Bsd: svgOS.FreeBSD,
        Router: svgOS.Router,
        CentOS: svgOS.CentOS,
        CentOs: svgOS.CentOS,
        Oracle: svgOS.Oracle,
        AlmaLinux: svgOS.AlmaLinux,
        KaliLinux: svgOS.KaliLinux,
        AstraLinux: svgOS.AstraLinux,
        AltLinux: svgOS.AltLinux,
        RedOS: svgOS.RedOs,
        RockyLinux: svgOS.RockyLinux,
        SoftEther: appIcons.SoftEther,
        Strongswan: appIcons.Strongswan,
        OtherProvider: ICON_WEB,
        AmazonAws: ICON_AMAZON_AWS,
        GoogleCloud: ICON_GOOGLE_CLOUD,
      },
      placeholderIcons: {
        filter: ICON_FILTER,
        sort: ICON_SORT,
      },
    }
  },
  computed: {
    multipleItems () {
      let str = ''
      this.options.forEach(o => {
        if (o.selected) {
          str += o.stateLocale + ', '
        }
      })
      return str.replace(/,\s*$/, '')
    },
    error () {
      return this.serverErrorData.length
    },
    valueTitle () {
      let option = this.options.find(o => o[this.fieldValue] === this.modelValue)
      return option ? option[this.fieldTitle] : this.label
    },
    showRightIconLabel () {
      let option = this.options.find(o => o[this.fieldValue] === this.modelValue)
      return this.rightIcon ? option[this.fieldRightIcon] === true : false
    },
    valueSubTitle () {
      if (!this.fieldSubTitle) {
        return ''
      }

      let option = this.options.find(o => o[this.fieldValue] === this.modelValue)
      return option ? option[this.fieldSubTitle] : ''
    },
    valueShortTitle () {
      if (this.options.find(x => x.Name === this.valueTitle)) {
        return this.options.find(x => x.Name === this.valueTitle).Name
      } else if (this.options.find(x => x[this.fieldTitle] === this.valueTitle)) {
        return this.options.find(x => x[this.fieldTitle] === this.valueTitle).VstackOsFamily || this.options.find(x => x[this.fieldTitle] === this.valueTitle).Id
      } else {
        return ''
      }
    },
    checked () {
      return !!this.options.map(o => o[this.fieldValue]).includes(this.modelValue)
    },
    showPlaceholder () {
      return this.full && this.modelValue != null
    },
    selectedLocation () {
      return this.options.find(x => x.City === this.valueTitle)
    },
    locationFlag () {
      return this.selectedLocation?.Flag
    },
    optionsCurrent () {
      return this.filterOptions ? this.optionsFiltered : this.options
    },
    getIconSelected() {
      if (this.fieldInlineIcon.length) {
        let selected = this.options.find(o => o[this.fieldValue] === this.modelValue)
        return selected[this.fieldInlineIcon]
      } else {
        return this.osMap[this.valueShortTitle]
      }
    },
  },
  watch: {
    valueTitle: function () {
      this.serverErrorData = []
    },
    serverError: function () {
      this.serverErrorData = this.serverError
    },
  },
  updated () {
    if (this.selectedOptionIndex !== -1) {
      let el = this.$refs.optionsItems.find(x => x.classList.contains('vue-dropdown-list-ps-item--selected'))
      if (el) {
        this.$refs.listOptions.$el.scroll({
          top: Math.max(0, el.offsetTop - 72),
          behavior: 'smooth',
        })
      }
    }
  },
  methods: {
    changeHandler (item, index) {
      if (item.ComingSoon) return
      this.selectedOptionIndex = index
      this.$emit('select', item)
      if (!this.multiselection) {
        this.open = false
      }
      this.$emit('update:modelValue', item[this.fieldValue])
    },
    onBlur () {
      setTimeout(() => {
        this.open = false
      }, 1)
    },
    setOpen () {
      if (this.disabled) {
        return
      }
      if (!this.open) {
        this.$refs.buttonLabel.focus()
      }
      this.open = !this.open
    },
    onEnter () {
      if (this.open) {
        if (this.selectedOptionIndex === -1) {
          this.$emit('update:modelValue', this.modelValue)
          this.$emit('enter')
        } else {
          this.$emit('update:modelValue', this.options[this.selectedOptionIndex][this.fieldValue])
          this.onBlur()
          this.$emit('enter')
        }
      } else {
        this.open = true
      }
    },
    onUp () {
      if (!this.options.length || this.selectedOptionIndex === 0) {
        return
      }

      if (this.open) {
        if (this.selectedOptionIndex - 1 >= 0) {
          this.selectedOptionIndex--
        } else {
          this.selectedOptionIndex = this.options.length - 1
        }
      } else {
        let itemIndex = this.options.findIndex(x => x[this.fieldValue] === this.modelValue)
        if (itemIndex - 1 >= 0) {
          this.$emit('update:modelValue', this.options[itemIndex - 1][this.fieldValue])
        } else {
          this.$emit('update:modelValue', this.options[this.options.length - 1][this.fieldValue])
        }
      }
    },
    onDown () {
      if (!this.options.length || this.selectedOptionIndex === this.options.length - 1) {
        return
      }

      if (this.open) {
        if (this.selectedOptionIndex + 1 < this.options.length) {
          this.selectedOptionIndex++
        } else {
          this.selectedOptionIndex = 0
        }
      } else {
        let itemIndex = this.options.findIndex(x => x[this.fieldValue] === this.modelValue)
        if (itemIndex + 1 < this.options.length) {
          this.$emit('update:modelValue', this.options[itemIndex + 1][this.fieldValue])
        } else {
          this.$emit('update:modelValue', this.options[0][this.fieldValue])
        }
      }
    },
    isSelectedItem (item, index) {
      if (index === this.selectedOptionIndex || this.modelValue === item.Id) return true
      return false
    },
    getIcon (item) {
      if (this.fieldInlineIcon.length) {
        return item[this.fieldInlineIcon]
      } else {
        return this.osMap[item[this.fieldValue]] ? this.osMap[item[this.fieldValue]] : this.osMap[item[this.fieldTitle]]
      }
    },
  },
}
</script>

<style lang="less" scoped>
.vue-dropdown {
  position: relative;
  width: 100%;
  font-size: 14px;
  font-weight: 500;

  &.small {
    & .vue-dropdown-label {
      height: 32px;
      padding: 0 8px;

      &::after {
        right: 9px;
      }

      &:focus {
        padding: 0 7px;

        &::after {
          right: 8px;
        }
      }
    }

    & .vue-dropdown-list-ps-item {
      padding: 10px 8px;
    }

    & .vue-dropdown-list.with-focus {
      top: 30px;
    }
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  &-placeholder {
    position: absolute;
    z-index: 1;
    font-size: 12px;
    font-weight: 500;
    transform: translate(20px, 10px);
  }

  &-error {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-danger);
    letter-spacing: 0.48px;
  }

  &-list {
    &-ps {
      &-item {
        display: flex;
        align-items: center;
        color: var(--color-base);

        & .location-country {
          color: var(--color-secondary);
        }

        &-flag {
          width: 16px;
          height: 16px;
          margin-right: 8px;

          :deep(svg) {
            border-radius: 50%;
          }
        }

        &.with-location {
          &.location-disabled {
            background-color: var(--color-disabled-primary);

            & .vue-dropdown-list-ps-item-flag {
              filter: grayscale(1);
              opacity: 0.5;
            }
          }
        }

        &:not(.with-location) {
          :deep(svg:not('.fixed-colors')) {
            margin-right: 10px;

            & path {
              fill: var(--menu-color-icon);
            }
          }
        }

        & .fieldtitle {
          &.with-icon {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }

    &-checkbox {
      align-items: center;
    }
  }

  &-label {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 15px 16px;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-base);
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--color-light);
    border: 1px solid var(--color-border);
    border-radius: 2px;

    &-ph-icon {
      margin-right: 5px;

      &.filter {
        :deep(svg) {
          height: 11px;

          & path {
            fill: var(--color-base);

            &:last-child {
              stroke: var(--color-base);
            }
          }
        }
      }

      &.sort {
        :deep(svg) {
          height: 12px;

          & path {
            fill: var(--color-base);
          }
        }
      }
    }

    &.error {
      border: 2px solid var(--color-danger);
    }

    &-first {
      display: flex;
      align-items: center;
      line-height: 22px;

      &.with-icon {
        gap: 8px;

        & .fieldtitle-icon {
          height: 16px;
        }
      }

      &-flag {
        width: 16px;
        height: 16px;
        margin-right: 8px;

        :deep(svg) {
          border-radius: 50%;
        }
      }
    }

    &-icon {
      display: flex;
      margin-right: 10px;

      &:not(.colored) {
        :deep(svg) {
          & path {
            fill: var(--menu-color-icon);
          }
        }
      }
      :deep(svg) {
        width: 20px;
        height: 20px;
      }
    }

    &.with-placeholder .vue-dropdown-label-first {
      position: relative;
      padding-top: 15px;
    }

    &.big {
      height: 60px;
    }

    &.unchecked {
      color: var(--color-secondary);
    }

    &::after {
      position: absolute;
      top: 48%;
      right: 20px;
      display: inline-block;
      width: 7px;
      height: 7px;
      content: " ";
      border-color: var(--menu-color-icon);
      border-style: none solid solid none;
      border-width: 1.5px;
      transition: all 0.2s;
      transform: translateY(-50%) rotate(45deg);
    }

    &:hover {
      border: 1px solid var(--color-disabled-secondary);
    }

    &:focus {
      padding: 14px 15px;
      outline: none;
    }

    &:active,
    &:focus,
    &:target,
    &:focus-visible {
      border: 2px solid var(--color-primary);
      outline-color: var(--color-primary);
    }

    &:disabled {
      background-color: var(--color-background-secondary);
      color: var(--color-disabled-secondary);
    }

    &:focus .vue-dropdown-label-first {
      left: -1px;
    }
  }

  &.open &-label::after {
    top: 53%;
    transform: translateY(-50%) rotate(225deg);
  }

  &.open &-placeholder {
    color: var(--color-primary);
  }

  &-list {
    &.with-focus {
      top: 38px;
    }

    position: absolute;
    right: 0;
    left: 0;
    z-index: 20;
    overflow-y: auto;
    text-align: left;
    background-color: var(--color-light);
    border: 2px solid var(--color-primary);
    border-radius: var(--border-radius);

    &-ps {
      max-height: 180px;

      &-item {
        padding: 10px 15px;
        font-size: 12px;

        &:hover {
          background-color: var(--color-primary-50);
        }

        &--selected {
          background-color: var(--color-background-secondary);

          & div {
            display: flex;
            align-items: center;
            width: 100%;
          }

          & .selected-icon {
            width: 10px;
            height: 6px;
            margin-bottom: 3px;
            margin-left: auto;
            border: 1px solid var(--color-base);
            border-top: 0;
            border-right: 0;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &-multiple {
    &-items {
      display: block;
      max-width: 145px;
      overflow: hidden;
      font-weight: 400;
      line-height: 22px;
      text-overflow: ellipsis;
    }

    &-empty-text {
      font-weight: 400;
      line-height: 22px;
    }
  }

  &.bg-grey {
    & .vue-dropdown-label,
    & .vue-dropdown-list {
      background-color: var(--color-background);
    }
  }

  &.mobile {
    & .vue-dropdown-label {
      height: 32px;
      padding: 15px 7px;

      &::after {
        top: 44%;
        right: 11px;
      }

      &:focus,
      &:active {
        padding: 14px 6px !important;

        &::after {
          top: 44%;
          right: 10px;
        }
      }
    }

    &.open {
      & .vue-dropdown-label {
        height: 32px;
        padding: 15px 7px;

        &::after {
          top: 54%;
        }
      }
    }

    & .vue-dropdown-list {
      &.with-focus {
        top: 31px;
      }

      &-ps-item {
        padding: 10px 8px;
      }
    }
  }
}

@media (max-width: 530px) {
  .vue-dropdown-label {
    font-size: 12px;
  }
  .vue-dropdown-label-icon {
    :deep(svg) {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
