
import ApiService from '@/api'

import {
  CDN_DOMAIN_ENABLE,
  CDN_DOMAIN_DISABLE,
  CDN_DELETE,
  CDN_ORDER_CANCEL,
  CDN_ORDER_DETAIL,
  CDN_ORDER_SAGA,
  CDN_DOMAIN_DETAIL,
  CDN_DOMAINS_LIST,
  CDN_DOMAIN_CONNECT_DELEGATION,
  CDN_DOMAIN_CONNECT_CNAME,
  CDN_ORDER_CALCULATOR,
  CDN_ORDER_ADD_RECORD,
  CDN_ORDER_DELETE_RECORD,
  CDN_ORDER_CONTINUE,
  CDN_GET_REMOTE_ORIGINS,
  CDN_GET_REMOTE_CERTIFICATES,
  CDN_PARSE_CERTIFICATE_FILES,
  CDN_DOMAIN_DELEGATED_UPDATE,
  CDN_DOMAIN_CNAME_UPDATE,
  CDN_DOMAIN_PURGE_CACHE,
  CDN_DOMAIN_REFILL_CACHE,
  CDN_DOMAIN_CERTIFICATE_UPDATE,
  CDN_PERSONAL_SETTINGS_ACTIVATE,
  CDN_PERSONAL_SETTINGS_MODIFY,
  CDN_ADD_LOCATION,
  CDN_DELETE_LOCATION,
  CDN_UPDATE_LOCATION,
  CDN_HEADER_VALIDATE,
  CDN_CORS_VALIDATE,
  CDN_S3_ORDER_CALCULATOR,
  CDN_S3_CREATE_ORDER,
  CDN_S3_ORDER_DETAIL,
  CDN_S3_LIST,
  CDN_S3_ORDER_CANCEL,
  CDN_S3_CREATE_OTHER_PROVIDER,
  CDN_S3_DETAIL,
  CDN_S3_RESOURCE_DELETE,
  CDN_S3_PARSE_ENDPOINT,
  CDN_S3_PARSE_BUCKET,
  CDN_S3_BUCKET_ENABLE,
  CDN_S3_BUCKET_DISABLE,
  CDN_S3_BUCKET_UPDATE,
  CDN_S3_BUCKET_CREATE,
  CDN_S3_BUCKET_DELETE,
  CDN_S3_RESOURCE_EDIT,
  CDN_S3_PERSONAL_SETTINGS_ACTIVATE,
  CDN_S3_PERSONAL_SETTINGS_MODIFY,
  CDN_S3_PURGE_CACHE,
  CDN_S3_GET_PROVIDERS,
  CDN_VOD_CALCULATOR,
  CDN_VOD_CONNECT,
  CDN_VOD_LIST,
  CDN_VOD_DELETE,
  CDN_VOD_ENABLE,
  CDN_VOD_DISABLE,
  CDN_VOD_DETAIL,
  CDN_VOD_RENAME,
} from '../actions.type'

import { CDN_CUSTOM_MODE_SET, CDN_S3_PROVIDERS_SET } from '../mutations.type'

import { CDN_S3_PROVIDERS } from '../getters.type'

const state = {
  customModelAllowed: false,
  providers: null,
}

// getters
const getters = {
  isCustomModeAllowed: state => state.customModelAllowed,
  [CDN_S3_PROVIDERS]: state => state.providers,
}

// actions
const actions = {
  [CDN_ORDER_CALCULATOR] () {
    return ApiService.get(`/app-api/cdn/orders/caclulator`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_DETAIL] (context, params) {
    return ApiService.get(`/app-api/cdn/domains/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_ENABLE] (context, params) {
    return ApiService.post(`/app-api/cdn/domains/${params.id}/enable`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_DISABLE] (context, params) {
    return ApiService.post(`/app-api/cdn/domains/${params.id}/disable`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DELETE] (context, params) {
    return ApiService.delete(`/app-api/cdn/domains/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_ORDER_CANCEL] (context, params) {
    return ApiService.delete(`/app-api/cdn/orders/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_ORDER_DETAIL] (context, params) {
    return ApiService.get(`/app-api/cdn/orders/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_ORDER_SAGA] (context, params) {
    return ApiService.get(`/app-api/cdn/sagas/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAINS_LIST] () {
    return ApiService.get(`/app-api/cdn/domains/list`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_CONNECT_DELEGATION] (context, model) {
    return ApiService.post(`/app-api/cdn/orders/delegated`, model, {
      'transformRequest': (data) => {
        return data
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_DELEGATED_UPDATE] (context, model) {
    return ApiService.post(`/app-api/cdn/domains/delegated/${model.id}`, model.data, {
      'transformRequest': (data) => {
        return data
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_CNAME_UPDATE] (context, model) {
    return ApiService.post(`/app-api/cdn/domains/cname/${model.id}`, model.data, {
      'transformRequest': (data) => {
        return data
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_CERTIFICATE_UPDATE] (context, model) {
    return ApiService.post(`/app-api/cdn/ssl-certificate/change/`, model.data, {
      'transformRequest': (data) => {
        return data
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_CONNECT_CNAME] (context, model) {
    return ApiService.post(`/app-api/cdn/orders/cname`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_ORDER_ADD_RECORD] (context, params) {
    return ApiService.post(`/app-api/cdn/orders/delegated/${params.orderId}/origins`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_ORDER_DELETE_RECORD] (context, params) {
    return ApiService.delete(`/app-api/cdn/orders/delegated/${params.orderId}/origins/${params.originId}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_ORDER_CONTINUE] (context, params) {
    return ApiService.post(`/app-api/cdn/orders/${params.orderId}/continue`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_GET_REMOTE_ORIGINS] (context, domain) {
    return ApiService.query(`/app-api/cdn/query/origins?domain=${domain}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_GET_REMOTE_CERTIFICATES] (context, domain) {
    return ApiService.query(`/app-api/cdn/query/ordered-ssl-certificate?domain=${domain}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_PARSE_CERTIFICATE_FILES] (context, model) {
    return ApiService.post(`/app-api/cdn/ssl-certificate/parse-files`, model, {
      'transformRequest': (data) => {
        return data
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_PURGE_CACHE] (context, params) {
    return ApiService.post(`/app-api/cdn/domains/${params.cdnDomainId}/purge-cache`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DOMAIN_REFILL_CACHE] (context, params) {
    return ApiService.post(`/app-api/cdn/domains/${params.cdnDomainId}/refill-cache`, params)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_PERSONAL_SETTINGS_ACTIVATE] (context, params) {
    return ApiService.post(`/app-api/cdn/domains/${params.cdnDomainId}/request-personal-settings-activation`, params)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_PERSONAL_SETTINGS_MODIFY] (context, params) {
    return ApiService.post(`/app-api/cdn/domains/${params.cdnDomainId}/request-personal-settings-modification`, params)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_ADD_LOCATION] (context, model) {
    return ApiService.post(`/app-api/cdn/domains/${model.cdnDomainId}/locations`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_DELETE_LOCATION] (context, model) {
    return ApiService.delete(`/app-api/cdn/domains/${model.cdnDomainId}/locations/${model.locationId}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_UPDATE_LOCATION] (context, model) {
    return ApiService.post(`/app-api/cdn/domains/${model.cdnDomainId}/locations/${model.locationId}`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_HEADER_VALIDATE] (context, model) {
    return ApiService.post(`/app-api/cdn/validators/request-header`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_CORS_VALIDATE] (context, model) {
    return ApiService.post(`/app-api/cdn/validators/cors-policy`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_ORDER_CALCULATOR] () {
    return ApiService.get(`/app-api/cdn/s3/orders/calculator`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_CREATE_ORDER] (context, model) {
    return ApiService.post(`/app-api/cdn/s3/orders`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_CREATE_OTHER_PROVIDER] (context, model) {
    return ApiService.post(`/app-api/cdn/s3/providers/request-new-s3-provider`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_ORDER_DETAIL] (context, params) {
    return ApiService.get(`/app-api/cdn/s3/orders/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_LIST] () {
    return ApiService.get(`/app-api/cdn/s3/list`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_ORDER_CANCEL] (context, params) {
    return ApiService.delete(`/app-api/cdn/s3/orders/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_RESOURCE_DELETE] (context, params) {
    return ApiService.delete(`/app-api/cdn/s3/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_DETAIL] (context, params) {
    return ApiService.get(`/app-api/cdn/s3/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_PARSE_ENDPOINT] (context, model) {
    return ApiService.post(`/app-api/cdn/validators/s3-resource-endpoint`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_PARSE_BUCKET] (context, model) {
    return ApiService.post(`/app-api/cdn/validators/s3-bucket-name`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_BUCKET_ENABLE] (context, params) {
    return ApiService.post(`/app-api/cdn/s3/${params.resourceId}/buckets/${params.bucketId}/enable`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_BUCKET_DISABLE] (context, params) {
    return ApiService.post(`/app-api/cdn/s3/${params.resourceId}/buckets/${params.bucketId}/disable`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_BUCKET_UPDATE] (context, model) {
    return ApiService.post(`/app-api/cdn/s3/${model.resourceId}/buckets/${model.bucketId}/update`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_BUCKET_CREATE] (context, model) {
    return ApiService.post(`/app-api/cdn/s3/${model.resourceId}/buckets`, model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_BUCKET_DELETE] (context, params) {
    return ApiService.delete(`/app-api/cdn/s3/${params.resourceId}/buckets/${params.bucketId}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_RESOURCE_EDIT] (context, params) {
    return ApiService.post(`/app-api/cdn/s3/${params.resourceId}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_PERSONAL_SETTINGS_ACTIVATE] (context, params) {
    return ApiService.post(`/app-api/cdn/s3/${params.resourceId}/request-personal-settings-activation`, params)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_PERSONAL_SETTINGS_MODIFY] (context, params) {
    return ApiService.post(`/app-api/cdn/s3/${params.resourceId}/request-personal-settings-modification`, params)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_PURGE_CACHE] (context, params) {
    return ApiService.post(`/app-api/cdn/s3/${params.resourceId}/buckets/${params.bucketId}/purge-cache`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_S3_GET_PROVIDERS] (context) {
    return ApiService.get('/app-api/cdn/s3/providers')
      .then(({ data }) => {
        context.commit(CDN_S3_PROVIDERS_SET, data)
        return data
      })
  },
  [CDN_VOD_CALCULATOR] () {
    return ApiService.get(`/app-api/cdn/vod/calculator`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_VOD_CONNECT] (context, params) {
    return ApiService.post(`/app-api/cdn/vod/s3-buckets`, params)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_VOD_LIST] () {
    return ApiService.get('/app-api/cdn/vod/list')
      .then(({ data }) => {
        return data
      })
  },
  [CDN_VOD_DELETE] (context, params) {
    return ApiService.delete(`/app-api/cdn/vod/${params.id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_VOD_ENABLE] (context, params) {
    return ApiService.post(`/app-api/cdn/vod/${params.id}/enable`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_VOD_DISABLE] (context, params) {
    return ApiService.post(`/app-api/cdn/vod/${params.id}/disable`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_VOD_DETAIL] (context, id) {
    return ApiService.get(`/app-api/cdn/vod/${id}`)
      .then(({ data }) => {
        return data
      })
  },
  [CDN_VOD_RENAME] (context, params) {
    return ApiService.post(`/app-api/cdn/vod/${params.vodId}/rename`, params.model)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
  [CDN_CUSTOM_MODE_SET] (state, value) {
    state.customModelAllowed = value
  },
  [CDN_S3_PROVIDERS_SET] (state, value) {
    state.providers = value
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
